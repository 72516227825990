import dyslexicBlacklist from 'lightning-common/lib/configuration/dyslexicBlacklist.json';
import BaseView from '../../../views/base';
import keycodes from '../../../util/keycodes';
const numbers = require('../../../../../lib/numbers.js');

const LanguageSelectionView = BaseView.extend({
    events: {
        'click .js-languageLink': 'languageLinkClicked',
        'keypress .js-languageLink': 'languageLinkPressed',
    },
    initialize({ preferencesModel }) {
        this.model = preferencesModel;
        this.listenTo(this.model, 'change', this.modelSave);
        const selectedLangLink = this.$el.find('.js-languageLink.selected');
        const selectedLangName = selectedLangLink.text();
        const languageCode = this.model.get('languageSelection');
        if (selectedLangName) {
            this.$el.find('.js-langDisplayText').text(selectedLangName);
        }

        // Update language if Cookie does not match selected language
        if (
            Cookies.get('language-selection') &&
            languageCode !== Cookies.get('language-selection')
        ) {
            this.updateLanguagePreference(
                Cookies.get('language-selection'),
                false
            );
        }
    },
    languageLinkClicked(e) {
        this.updateLanguagePreference($(e.currentTarget).data('code'), true);
    },
    languageLinkPressed(e) {
        if (e.keyCode === keycodes.ENTER) {
            this.updateLanguagePreference(
                $(e.currentTarget).data('code'),
                true
            );
        }
    },
    updateLanguagePreference(code, clicked) {
        const updatedPrefs = {
            languageSelection: code,
        };

        // If the language does not support dyslexic font, remove the dyslexic cookie
        if (dyslexicBlacklist[code]) {
            if (Cookies.get('dyslexic-setting')) {
                // If on the settings page and the user changed the dyslexic setting,
                // the language selection preferenceModel would not be updated, only the
                // settings page preferencesModel would.  This accounts for that change.
                this.model.attributes.dyslexic = true;
            }

            Cookies.remove('dyslexic-setting', { path: '/' });
            updatedPrefs.dyslexic = false;
        }

        this.model.set(updatedPrefs);

        if (window.OverDrive.isAuthenticated) {
            this.model.save(this.model.getPatchData(), {
                patch: true,
                success: () => {
                    this.setCookieAndRefresh(code, clicked);
                },
            });
        } else {
            this.setCookieAndRefresh(code, clicked);
        }
    },
    setCookieAndRefresh(languageSelectionValue, clicked) {
        // Delete any existing language selection cookie to prevent edge case where multiple cookies are set
        Cookies.remove('language-selection', { path: '/' });

        let domain = window.location.hostname || 'overdrive.com';
        domain = '.' + domain.split('.').splice(-2).join('.'); // grab everything after second-to-last period

        Cookies.set('language-selection', languageSelectionValue, {
            httpOnly: false,
            expires: new Date(Date.now() + numbers.yearInMilliseconds),
            domain: domain,
            overwrite: true,
            path: '/',
            sameSite: 'lax',
            secure: true,
        });

        // Prevents refresh loop when errors occur or cannot retrieve auth
        if (window.OverDrive.authType !== 'error' || clicked) {
            window.location.reload();
        }
    },
});

export default LanguageSelectionView;
