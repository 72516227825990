const { html } = window;

export default ({ hooks, toasterType, id, messageId, message }) => `
<div class="Toaster-toast ${hooks.toast.substring(1)} ${toasterType} animated fadeIn" role="alert">
    <div class="row">
        <div class="large-10 large-centered medium-10 medium-centered columns">
            <div class="Toaster-bread">
                <span role="alert" class="Toaster-message ${hooks.message.substring(1)} Toaster-messageContent" 
                    tabindex="0" id="${id}" data-message-id="${messageId}">${message}</span>
                <button class="${hooks.close.substring(1)} Toaster-close" aria-label="${html('toasts.dismissAria')}">&times;</button>
            </div>
        </div>
    </div>
</div>
`;
